// Base theme styles.
@import '../../steindom_theme/less/base';

// Variables.
@import 'variables';

// Top wrapper.
.page-top {
  background-size: cover;
  background-position: center;
  background-color: @theme-top-bg-color;

  // Makes mobile menu not expand the background image in a distracting way.
  background-attachment: fixed;
  @media @mobile-only {
    background-attachment: scroll;
  }
}

// Quick links.
.page-quick-links {
  background-color: fade(@theme-quick-links-bg-color, 60%);
  margin-bottom: 3px;

  .panel-pane {
    margin: 0;
  }

  .row {
    min-height: 32px;
    padding: 5px 0;
  }

  @media @mobile-only {
    .row > div + div {
      margin-top: 5px;
    }
  }

  .pane-social-media-link-widget {
    text-align: center;
    @media @tablet {
      text-align: left;
    }
    li {
      padding: 0 6px 0 0;
    }
    img {
      width: 22px;
      image-rendering: -webkit-optimize-contrast;
    }
  }

  .pane-menu-quick-links {
    text-align: center;
    text-transform: uppercase;

    @media @tablet {
      text-align: right;
    }

    .pane-content {
      ul {
        margin: 0;
      }
      a {
        color: @theme-quick-links-link-color;
        font-family: @font-family-sans-serif;
        font-size: 14px;
        font-weight: bold;
      }
    }
  }
}

// Header.
.page-header {
  background-color: fade(white, 70%);

  .panel-pane {
    margin-bottom: 0;
  }

  @media @mobile-only {
    > .container > .row > div + div {
      margin: 15px 0;
    }
  }

  // Header mini panel.
  .pane-hosted-grace-header {
    margin: 15px 0;
  }

  // Site name.
  .pane-page-site-name {
    font-size: 24px;
    line-height: 1.2em;
    @media @mobile-only {
      text-align: center;
    }
  }

  // Site slogan.
  .pane-page-slogan {
    font-size: 18px;
    @media @mobile-only {
      font-size: 16px;
      text-align: center;
    }
  }

  // Give nav vertical flex height.
  .page-nav,
  .pane-superfish-1,
  .pane-superfish-1 > .pane-content {
    @media @tablet {
      display: flex;
      align-self: stretch;
      justify-content: flex-end;
    }
    @media @desktop {
      justify-content: flex-start;
    }
  }

  // Sticky nav.
  .sticky-nav.affix {
    left: 0;
    background-color: fade(white, 90%);
    justify-content: center;
  }

  // Nav.
  .pane-superfish-1 .pane-content {
    .superfish-base();
    .superfish-icons(@position: false);
    .superfish-accordion-bg-colors(@color: @theme-submenu-bg-color);

    font-family: @font-family-sans-serif;
    font-size: 18px;

    .sf-accordion {
      margin-top: 10px;
      background-color: @theme-submenu-bg-color;
      a {
        color: white;
      }
    }

    .sf-horizontal {
      display: flex;
      flex-wrap: wrap;

      a {
        color: @theme-main-menu-link-color;
      }

      > .sf-depth-1 {
        display: flex;
        align-items: center;
        border-top: 7px solid transparent;
        padding: 7px;
        @media @desktop {
          padding: 10px;
        }
        @media @large {
          padding: 15px;
        }
        &:hover {
          background-color: fade(white, 40%);
        }
        &.active-trail {
          background-color: white;
          border-top-color: @theme-main-menu-link-color;
        }
        > a {
          font-size: 20px;
        }
      }

      ul {
        background-color: white;
        padding: 10px 0;
        box-shadow: 0px 5px 5px rgba(0, 0, 0, .3);
        a {
          padding: 5px 15px;
          &:hover {
            background-color: fade(black, 10%);
            text-decoration: none;
          }
        }
      }

      .sf-megamenu a.menuparent:after {
        content: '';
        display: block;
        border-bottom: 1px solid @theme-main-menu-link-color;
        margin-top: 4px;
      }
    }
  }

  // Search form.
  .pane-hosted-search-form {
    .form-text:extend(.input-sm) {}
    .form-submit:extend(.btn-sm) {}
    .btn {
      font-weight: normal;
    }
    @media @mobile-only {
      text-align: center;
    }
  }

  // Show branding search form on tablet only.
  .page-branding .pane-hosted-search-form {
    display: none;
    @media @tablet-only {
      display: block;
      margin-bottom: 15px;
    }
  }
}

// Page title.
.page-title {
  background-color: @theme-page-title-bg-color;
  padding: 25px 0;
  text-align: center;
  color: white;

  .panel-pane {
    margin: 0;
  }

  h1 {
    margin: 0;
    line-height: 1;
  }

  body.with-page-image & {
    background-color: fade(black, 60%);
    margin-top: 150px;
    @media @tablet {
      margin-top: 400px;
    }
  }

  body.without-page-title & {
    background-color: transparent !important;
  }
}

// Page content.
.page-content {
  padding-top: 30px;
}

// Footer.
.page-footer {
  background-color: @theme-footer-bg-color;
  padding: 60px 0;

  .pane-title, .pane-content {
    color: @theme-footer-text-color;

    a:not(.btn) {
      text-decoration: underline;
      color: @theme-footer-link-color;
      &:hover {
        color: white;
      }
    }
  }
}

// Credit.
.page-credit {
  margin: 10px 0 20px;
  .panel-pane {
    margin-bottom: 0;
  }
  .pane-content {
    font-size: 15px;
    line-height: 1;
    text-align: center;
    color: #383838;
    .opacity-hover(1, .6);
    a {
      text-decoration: none;
      color: inherit;
    }
    &:hover a {
      text-decoration: underline;
    }
  }
}

// Pane styles.
@import 'pane-styles';

// Submenu.
.pane-submenu {
  .pane-style(@theme-submenu-bg-color);

  > .pane-content {
    font-size: 20px;
    .submenu-arrows(@collapsed: '\e250', @expanded: '\e252');
    .submenu-font-size-tier();
    > div > ul {
      padding-left: 1em;
    }
    a {
      padding: 0.4em 0.4em;
      text-decoration: none !important;
      &.active {
        font-weight: bold;
      }
      &:hover {
        text-decoration: underline !important;
      }
    }
  }
}

// WYSIWYG links.
.filter-wysiwyg a {
  text-decoration: underline;
  &.btn {
    text-decoration: none;
  }
}

// Stuff that should be sans-serif.
.pane-page-messages,
.pane-page-tabs,
.form-control {
  font-family: @font-family-sans-serif;
}

// Buttons.
.btn {
  font-family: @font-family-sans-serif;
  font-weight: bold;
}

// Uppercase some headings.
h4, h5, h6 {
  text-transform: uppercase;
}

@brand-warning:#153889;@accordion-heading-bg-color:#153889;@font-family-sans-serif:'Open Sans', sans-serif;@font-family-serif:'Cinzel', sans-serif;@font-family-base:@font-family-sans-serif;@theme-quick-links-bg-color:#153889;@theme-main-menu-link-color:#153889;@theme-footer-bg-color:darken(#4A4A4A, 3%);@theme-pane-style-attention-bg-color:#153889;
@brand-warning: #153889;@accordion-heading-bg-color: #153889;@font-family-sans-serif: 'Open Sans', sans-serif;@font-family-serif: 'Cinzel', sans-serif;@font-family-base: @font-family-sans-serif;@theme-quick-links-bg-color: #153889;@theme-main-menu-link-color: #153889;@theme-footer-bg-color: darken(#4A4A4A, 3%);@theme-pane-style-attention-bg-color: #153889;